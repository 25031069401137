






















































import ProductApi from "@/apis/product/ProductApi";
import SellingApi from "@/apis/stock/SellingStockApi";
import BaseForm from "@/components/dialog/BaseForm.vue";
import ProductCategories from "@/components/ui/ProductCategories.vue";
import Products from "@/components/ui/Products.vue";
import ValidationSelect from "@/components/ui/ValidationSelect.vue";
import ValidationTextField from "@/components/ui/ValidationTextField.vue";
import { BaseRepo } from "@/models/BaseRepo";
import { SellingStock } from "@/models/SellingStock";
import { ProductCategory } from "@/models/Product";
import BaseView from "@/views/BaseView.vue";
import ProductItems from "@/views/models/admin/ProductItems";
import { Component, Emit, Prop } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";

@Component({
  components: {
    BaseForm,
    ValidationTextField,
    ValidationSelect,
    ProductCategories,
    Products,
  },
})
export default class CreateSellForm extends BaseView {
  @Emit()
  private cancel() {
    return false;
  }

  @Emit()
  private submit(data: any) {
    return data;
  }
  @Prop() StockType!: boolean;

  @Prop() categories!: Array<ProductCategory>;

  $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
  };

  private sellingApi: SellingApi = new SellingApi();
  private productApi: ProductApi = new ProductApi();
  private sellingStock!: SellingStock;
  private product: Array<ProductItems> = [];
  private productCategoryId = 0;
  private productCategoryType = "sell";

  private params = {
    product_id: 0,
    possible_count: 0,
    use_count: 0,
    disabled_count: 0,
    remark: "",
  } as SellingStock;

  private async save() {
    const result = await this.$refs.observer.validate();
    if (result) {
      this.isConnecting = true;

      try {
        const repo = await this.sellingApi.create(this.params);
        const data: any = repo.data as BaseRepo;
        this.submit(data);
      } catch (e) {
        this.handleError(e);
      } finally {
        this.isConnecting = false;
      }
    }
  }

  private async onSelectProductCategory(category_id: any) {
    this.productCategoryId = category_id;

    try {
      const params = {
        productCategoryType: this.productCategoryType,
        productCategoryId: this.productCategoryId,
      };
      const repo: BaseRepo = await this.productApi.getEnabledProducts(params);
      this.product = [...repo.data];
    } catch (e) {
      this.handleError(e);
    }
  }

  private onSelectProduct(select_id: any) {
    this.params.product_id = select_id;
  }
}
