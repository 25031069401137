

















































import { Component, Emit, Prop } from "vue-property-decorator";
import BaseView from "@/views/BaseView.vue";
import BaseForm from "@/components/dialog/BaseForm.vue";
import ValidationTextField from "@/components/ui/ValidationTextField.vue";
import ValidationSelect from "@/components/ui/ValidationSelect.vue";
import SellingApi from "@/apis/stock/SellingStockApi";
import { ValidationObserver } from "vee-validate";
import SellingStockItem from "@/views/models/admin/SellingStockItem";

@Component({
  components: {
    BaseForm,
    ValidationTextField,
    ValidationSelect,
    ValidationObserver,
  },
})
export default class EditSellForm extends BaseView {
  $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
  };

  @Emit()
  private cancel() {
    return false;
  }

  @Emit()
  private submit(data: SellingStockItem) {
    return data;
  }
  @Prop() item!: SellingStockItem;
  @Prop() StockType!: boolean;

  private editItem!: SellingStockItem;

  private sellingApi = new SellingApi();
  private product_name = "";

  protected created() {
    this.editItem = { ...this.item };
    this.product_name =
      this.item.product.name + "(" + this.item.product.model_name + ")";
  }

  private async save() {
    if (!(await this.$refs.observer.validate())) return;

    this.isConnecting = true;

    try {
      const repo = await this.sellingApi.update(this.editItem);
      const data = repo.data;
      this.submit(data);
    } catch (e) {
      this.handleError(e);
    }

    this.isConnecting = false;
  }
}
