













































































































































































import SellingApi, { SellingSearchParams } from "@/apis/stock/SellingStockApi";
import OrgCategory from "@/components/ui/OrgCategory.vue";
import OrgSearch from "@/components/ui/OrgSearch.vue";
import ProductCategories from "@/components/ui/ProductCategories.vue";
import { Org } from "@/models/Org";
import Pagenation from "@/models/Pagenation";
import { ProductCategory } from "@/models/Product";
import ArrayUtil from "@/utils/ArrayUtil";
import BaseView from "@/views/BaseView.vue";
import ProductItems from "@/views/models/admin/ProductItems";
import SellingStockItem from "@/views/models/admin/SellingStockItem";
import { Component } from "vue-property-decorator";
import CreateForm from "./forms/CreateSellingForm.vue";
import EditForm from "./forms/EditSellingForm.vue";
import MoveForm from "./forms/MoveSellingForm.vue";
import SellForm from "./forms/SellingForm.vue";
import ReclaimForm from "./forms/ReclaimSellingForm.vue";

@Component({
  components: {
    CreateForm,
    EditForm,
    MoveForm,
    OrgSearch,
    OrgCategory,
    ProductCategories,
    ReclaimForm,
    SellForm,
  },
})
export default class Main extends BaseView {
  private productHeaders: any = [
    { text: "No", value: "id" },
    {
      text: "제품",
      align: "start",
      sortable: false,
      value: "product_name",
    },
    { text: "재고", value: "possible_count" },
    { text: "판매", value: "use_count" },
    { text: "불량", value: "disabled_count" },
    { text: "기능", value: "actions", sortable: false },
    {
      text: "회수",
      value: "reclaim",
      sortable: false,
    },
  ];

  private isCreating = false;
  private isEditing = false;
  private isMoving = false;
  private isSelling = false;
  private isReclaim = false;
  private selectedItem!: SellingStockItem;

  private sellingApi: SellingApi = new SellingApi();
  private productItems: Array<ProductItems> = [];
  private productCategoryItems: Array<ProductCategory> = [];
  private items: Array<SellingStockItem> = [];
  private selectedOrg!: Org | null;
  private pagenation = new Pagenation();
  private selectedCtegoryId = 0;

  private params: SellingSearchParams = {
    org_category: 1,
    product_category_id: 0,
    per_page: 10,
    page: 1,
  };

  private getColor(stock: any) {
    if (stock > 400) return "green";

    if (stock > 200) return "orange";

    return "red";
  }

  protected created() {
    this.loadDatas(1);
  }

  private async loadDatas(page: number) {
    if (this.isConnecting) return;

    this.isConnecting = true;
    this.items = [];
    this.params.page = page;
    this.params.org_id = this.selectedOrg?.id;

    try {
      const repo = await this.sellingApi.index(this.params);
      repo.data.per_page = Number(repo.data.per_page);
      this.pagenation = repo.data;
      this.items = [...this.pagenation.data];
    } catch (e) {
      this.handleError(e);
    }
    this.isConnecting = false;
  }

  private onSelectOrgCategory(category_id: number) {
    this.params.org_category = category_id;
    this.params.product_category_id = 0;
    this.selectedOrg = null;

    //본사를 선택했을때만 검색
    category_id === 1 && this.loadDatas(1);
  }

  private onSelectProductCategory(category_id: number) {
    this.params.product_category_id = category_id ?? category_id;
    this.loadDatas(1);
  }

  private onSelectOrg(org: Org) {
    this.selectedOrg = { ...org };
    this.params.product_category_id = 0;

    this.loadDatas(1);
  }

  private onClickCreated() {
    this.isCreating = true;
  }

  private onClickSell(item: SellingStockItem) {
    this.isSelling = true;
    this.selectedItem = { ...item };
  }

  private onClickEdit(item: SellingStockItem) {
    this.isEditing = true;
    this.selectedItem = { ...item };
  }

  private onClickMove(item: SellingStockItem) {
    this.isMoving = true;
    this.selectedItem = { ...item };
  }

  private onClickReclaim(item: SellingStockItem) {
    this.isReclaim = true;
    this.selectedItem = { ...item };
  }

  private async onClickDelete(item: SellingStockItem) {
    this.$confirm("확인", "삭제하시겠습니까?", async (result: boolean) => {
      if (!result) return;

      this.isConnecting = true;

      try {
        const repo = await this.sellingApi.delete(item.id);
        const data = repo.data;
        ArrayUtil.removeById(this.items, data);
      } catch (e) {
        this.handleError(e);
      }

      this.isConnecting = false;
    });
  }

  private onCreateProduct(item: SellingStockItem) {
    this.isCreating = false;

    const items = [...this.items];

    ArrayUtil.replaceOrPushById(items, item);

    this.items = items;

    this.$showSnack("상품이 추가되었습니다.");
  }

  private onProductUpdate(item: SellingStockItem) {
    this.isEditing = false;

    ArrayUtil.replaceById(this.items, item);

    this.$showSnack("상품이 수정되었습니다.");
  }

  private onProductMove(item: SellingStockItem) {
    this.isMoving = false;

    ArrayUtil.replaceById(this.items, item);

    this.$showSnack("상품이 이동되었습니다.");
  }

  private onProductSell(item: SellingStockItem) {
    this.isSelling = false;

    ArrayUtil.replaceById(this.items, item);

    this.$showSnack("상품판매가 저장되었습니다.");
  }

  private onProductReclaim(item: SellingStockItem) {
    this.isReclaim = false;

    ArrayUtil.replaceById(this.items, item);

    this.$showSnack("상품이 회수되었습니다.");
  }
}
