
































































import SellingApi from "@/apis/stock/SellingStockApi";
import BaseForm from "@/components/dialog/BaseForm.vue";
import OrgSearch from "@/components/ui/OrgSearch.vue";
import OrgCategory from "@/components/ui/OrgCategory.vue";
import ValidationSelect from "@/components/ui/ValidationSelect.vue";
import ValidationTextField from "@/components/ui/ValidationTextField.vue";
import { BaseRepo } from "@/models/BaseRepo";
import { Org } from "@/models/Org";
import BaseView from "@/views/BaseView.vue";
import SellingStockItem from "@/views/models/admin/SellingStockItem";
import { Component, Emit, Prop } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";

@Component({
  components: {
    BaseForm,
    ValidationTextField,
    ValidationSelect,
    OrgSearch,
    OrgCategory,
    ValidationObserver,
  },
})
export default class MoveSellingForm extends BaseView {
  $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
  };
  @Emit()
  private cancel() {
    return false;
  }

  @Emit()
  private submit(data: SellingStockItem) {
    return data;
  }
  @Prop() moveItem!: SellingStockItem;
  @Prop() StockType!: boolean;

  private productName = "";
  private toUser = 0;
  private sellingApi: SellingApi = new SellingApi();
  private selectedOrg!: Org;
  private selectedCtegoryId = 0;
  // private logType = "move";

  protected created() {
    this.productName =
      this.moveItem.product.name + "(" + this.moveItem.product.model_name + ")";
  }

  private async save() {
    if (!(await this.$refs.observer.validate())) return;

    if (this.toUser == 0) {
      this.$alert("알림", "시설을 선택해주세요.");
      return;
    }

    if (this.moveItem.moveCount > this.moveItem.possible_count) {
      this.$alert("알림", "재고량보다 많이 이동할 수 없습니다.");
      return false;
    }

    this.isConnecting = true;
    this.moveItem.to = this.toUser;

    try {
      const repo = await this.sellingApi.move(this.moveItem);
      const data: any = repo.data as BaseRepo;
      this.submit(data);
    } catch (e) {
      this.handleError(e);
    } finally {
      this.isConnecting = false;
    }
  }

  private onSelectOrgCategory(category_id: number) {
    this.selectedCtegoryId = category_id;
  }
  private onSelectOrg(org: Org) {
    this.selectedOrg = { ...org };
    this.toUser = this.selectedOrg.id;
  }
}
